const linksData = [
  {
    name: 'BlueSky',
    url: 'https://bsky.app/profile/gunnargrosch.com',
    icon: 'FaBluesky',
  },
  {
    name: 'LinkedIn',
    url: 'https://www.linkedin.com/in/gunnargrosch/',
    icon: 'FaLinkedin',
  },
  {
    name: 'Instagram',
    url: 'https://www.instagram.com/gunnargrosch/',
    icon: 'FaInstagram',
  },
  {
    name: 'Mastodon',
    url: 'https://hachyderm.io/@gunnargrosch',
    icon: 'FaMastodon',
  },
  {
    name: 'Threads',
    url: 'https://threads.net/@gunnargrosch/',
    icon: 'FaThreads',
  },
  {
    name: 'Twitter',
    url: 'https://twitter.com/gunnargrosch',
    icon: 'FaTwitter',
  },
  {
    name: 'YouTube',
    url: 'https://www.youtube.com/GunnarGrosch?sub_confirmation=1',
    icon: 'FaYoutube',
  },
  {
    name: 'GitHub',
    url: 'https://github.com/gunnargrosch/',
    icon: 'FaGithub',
  },
];

export default linksData;
